import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


/* swiper */
import 'swiper/dist/css/swiper.min.css';

/* jquery */
import $ from 'jquery'
Vue.prototype.$ = $;
window.$ = $ 
/* bootstarp */
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap-icons/font/bootstrap-icons.css';
/* 重置样式 */
import './assets/css/reset.min.css'

/* 头部组件 */
import HeaderVue from './components/HeaderVue'
Vue.component(HeaderVue.name,HeaderVue)


/* 尾部组件 */
import FooterVue from './components/FooterVue'
Vue.component(FooterVue.name,FooterVue)

/* 回到顶部 */
import GoTop from './components/GoTop'
Vue.component(GoTop.name,GoTop)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next();
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
