<template>
  <div id="footer" class="container-fluid">
    <div class="logo" v-if="false">
      <img src="@/assets/img/logo_white.png" alt="logo图" />
    </div>
    <p class="title" v-if="false">公司企业网站模板</p>
    <p v-if="false" class="address_tel_fax">
      <span>地址：公司地址公司地址公司地址公司地址</span>
      <span>Tel：888-888-888</span>
      <span>Fax：8888-88888888</span>
    </p>
    <p v-if="false" class="email_wx">
      <span>邮箱：liyunkun_11@163.com</span>
      <span>公司微信号：ilyk_gg</span>
    </p>
    <div class="container-bmc">
      <div class="powerby jc-between">
        <div class="lbox">
          <div class="lbox_li">电话：0755-82556390</div>
          <div class="lbox_li">传真：0755-82550861</div>
          <div class="lbox_li">地址：深圳市南山区科技园清华信息港科研楼515</div>
          <div class="lbox_li">邮箱：service@yykt100.com</div>
          <div class="lbox_li">24小时热线电话：13006665968</div>
        </div>
        <div class="rbox ai-center">
          <p class="text-center">
            <img
              src="../assets/img/医云康泰@2x.png"
              alt="二维码"
              class="img-fluid"
            /><br />微信公众号
          </p>
        </div>
      </div>
      <p class="copy">
        Copyright &copy; 2015 - 2024 深圳市前海云恒丰科技有限公司
        <a href="https://beian.miit.gov.cn" target="_blank">医云康泰®粤ICP备15069919号</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterVue",
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
#footer {
  width: 100%;
  height: 100%;
  color: #fff;
  // background: #474747;
  background: #f5f7fa;
  padding: 0px;
  overflow: hidden;
  // text-align: center;
  padding-top: 5.2rem;
  padding-bottom: 2rem;
  color: #333333;
  .container-bmc {
    padding: 0 20px;
    @media screen and (min-width: 768px) {
      width: 86%;
      margin: auto;
      max-width: 1500px;
    }
    .lbox {
      .lbox_li {
        margin-bottom: 1.2rem;
      }
    }
    .jc-between {
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
      }
      .rbox {
        @media screen and (min-width: 768px) {
          display: flex;
          align-items: center;
        }
        .text-center {
          text-align: center;
        }
        p {
          font-size: 16px;
        }
        img {
          width: 95px;
          margin-bottom: 5px;
          display: inline;
        }
      }
    }
    .copy {
      text-align: center;
      @media screen and (max-width: 997px) {
        font-size: 12px;
        margin: 30px 0 10px;
      }
    }
  }
}
.logo {
  width: 95px;
  height: 45px;
  margin: 50px auto 20px;
}
.title {
  font-size: 25px;
  margin-bottom: 20px;
}
.address_tel_fax {
  color: #d3d3d3;
  font-size: 14px;
  margin: 10px 0;
}
.email_wx {
  color: #d3d3d3;
  font-size: 14px;
}
.copy {
  color: #333333;
  font-size: 14px;
  margin: 50px 0 10px;
  a {
    color: #000;
  }
}
@media screen and (max-width: 997px) {
  .title {
    font-size: 20px;
  }
  .address_tel_fax {
    font-size: 12px;
  }
  .email_wx {
    font-size: 12px;
  }
  .copy {
    font-size: 12px;
    margin: 30px 0 10px;
  }
}
</style>
