<template>
  <div v-if="visible" class="fullscreen-dialog">
    <div class="dialog-wrapper">
      <div class="header">
        <!-- 关闭按钮 -->
        <button class="close-button" @click="closeDialog">
          <!-- 关闭图标 -->
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
      <div class="video-container">
        <!-- 视频播放器 -->
        <video
          ref="videoPlayer"
          class="video-player"
          controls
          :src="videoSrc"
          @loadedmetadata="playVideo"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullScreenDialog",
  props: {
    visible: {
      // 控制对话框是否可见
      type: Boolean,
      required: true,
    },
    videoSrc: {
      // 视频来源
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.visible) {
      this.openDialog();
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.openDialog();
      }
    },
  },
  methods: {
    openDialog() {
      // 打开对话框
      document.documentElement.style.overflow = "hidden";
      document.body.appendChild(this.$el);
    },
    closeDialog() {
      // 关闭对话框
      document.documentElement.style.overflow = "";
      //   this.$el.remove();
      console.log(111);
      this.$emit("update:visible", false); // 只需要改变visible值即可
    },
    playVideo() {
      // 播放视频
      this.$refs.videoPlayer.play();
    },
  },
};
</script>

<style scoped>
.fullscreen-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 1); /* 背景颜色和透明度 */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.dialog-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  z-index: 999;
}

.close-button {
  font-size: 24px; /* 调整图标大小 */
  color: #ffffff;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.video-player {
  max-width: 100%;
  max-height: 100%;
  width: 100%; /* 添加此行 */
  /* height: 100%; 添加此行 */
}
</style>
