<template>
  <div id="HomePage">
    <!-- 轮播图 -->
    <div id="swiper" v-if="false" class="container-fuild">
      <div class="swiper-container banner-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in swiperList" :key="index">
            <img class="swiper-lazy" :data-src="item.img" alt="轮播图" />
            <div class="swiper-lazy-preloader"></div>
            <div class="swiper-slide-title">
              <h1>{{ item.title }}</h1>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>

        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <!-- 医云康泰慢病管理系统 -->
    <div class="z_mobile">
      <div
        class="lazy"
        :style="{
          backgroundImage: `url(${require('../assets/img/观看视频bg.png')})`,
          backgroundAttachment: 'inherit',
        }"
      ></div>
      <div class="scl_text">
        <p class="scl_title serb_cont2">医云康泰慢病管理系统</p>
        <p class="scl_des serb_cont2 d-none d-sm-block">
          基于大数据、AI、云计算等新技术实现医疗数据的实时采集与交互打通阻塞，改善院内就医流程，开拓院外发展业务，将数字健康管理融入临床业务、居家和社区生活日常，创建“一方采集，多方利用”医疗数据的创新共享模式，提高心血管风险综合管控，达成数字医疗赋能患者管理，提升基层医疗服务能力的目标，促进多医学健康学科专业与信息技术专业相融合。
        </p>
        <button type="button" @click="showDialog" class="btn btn-primary">
          观看视频
        </button>
      </div>
    </div>
    <!-- 产品方案 -->
    <div class="productScheme">
      <div class="product_box container">
        <h1 class="pb_title">产品方案</h1>
        <div
          class="pb_content_ul"
          :class="{ row: false }"
          @mouseleave="pb_content_ul_mouseleaver($event)"
        >
          <div
            v-for="(item, index) in productSchemeList"
            @mouseenter="pb_content_li_mouseenter($event)"
            class="pb_content_li"
            :class="{ 'col-sm-12': false }"
            :key="index"
          >
            <div class="pc_li_c1">
              <div class="plc1_title">{{ item.title }}</div>
              <div class="plc1_img">
                <!-- <img src="../assets/img/圆角矩形 7@2x.png" alt="血压" /> -->
                <img :src="item.logo" :alt="item.title" />
              </div>
            </div>
            <div class="pc_li_c2">
              <div class="plc2_title">{{ item.productScheme }}</div>
              <div class="plc2_line"></div>
              <div class="plc2_desc">
                {{ item.content }}
              </div>
              <div class="plc2_btn" v-if="item.btnflag">
                <div class="plc2_button">查看更多</div>
              </div>
              <div class="plc2_img" v-if="false">
                <!-- <img src="../assets/img/圆角矩形 7@2x.png" alt="血压" /> -->
                <img :src="item.logo" alt="血压" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务领域 -->
    <div class="serviceSector">
      <div class="service_box container">
        <h1 class="pb_title">服务领域</h1>
      </div>
    </div>
    <div class="serviceSector serviceSector_con">
      <div class="service_box container">
        <div class="row serb_cont">
          <div class="col-md-4 sc_left_con">
            <div class="sc_left">
              <div class="scl_imgbox">
                <!-- <img src="../assets/img/私有化@2x.png" alt="" /> -->
                <div
                  class="scl_imgbg"
                  :style="{
                    backgroundImage: `url(${require('../assets/img/私有化@2x.png')})`,
                  }"
                ></div>
              </div>
              <div class="scl_text">
                <p class="scl_title">企业-私有化</p>
                <p class="scl_des">适合对数据有极高安全需求的企业</p>
              </div>
            </div>
          </div>
          <div class="col-md-8 sc_right">
            <div class="row scr_cont">
              <div class="col-md-4 scr_li">
                <div class="scr_li_textbox">
                  <h3 class="slt_title"><strong>个性化定制</strong></h3>
                  <p class="slt_des">根据客户需求和情况，定制使用功能</p>
                  <div class="scr_tags">
                    <span class="scrt_tag"
                      ><span class="scrtt_content">灵活性</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">专业性</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">成熟管理</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 scr_li">
                <div class="scr_li_textbox">
                  <h3 class="slt_title"><strong>数据安全</strong></h3>
                  <p class="slt_des">管理决策全面连接,上下游,智能数字化</p>
                  <div class="scr_tags">
                    <span class="scrt_tag"
                      ><span class="scrtt_content">脱敏处理</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">安全性</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">隐秘性</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 scr_li">
                <div class="scr_li_textbox">
                  <h3 class="slt_title"><strong>拓展性强</strong></h3>
                  <p class="slt_des">支持扩展开发</p>
                  <div class="scr_tags">
                    <span class="scrt_tag"
                      ><span class="scrtt_content">快速迭代</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">在线升级</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 scr_li">
                <div class="scr_li_textbox">
                  <h3 class="slt_title"><strong>内外网隔离</strong></h3>
                  <p class="slt_des">局域网+外网使用，发挥综合优势</p>
                  <div class="scr_tags">
                    <span class="scrt_tag"
                      ><span class="scrtt_content">前置机</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">虚拟机</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">防火墙</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 scr_li">
                <div class="scr_li_textbox">
                  <h3 class="slt_title"><strong>兼容性高</strong></h3>
                  <p class="slt_des">最小的调整成本实现对接现有系统</p>
                  <div class="scr_tags">
                    <span class="scrt_tag"
                      ><span class="scrtt_content">多样性</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">开放性</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 scr_li">
                <div class="scr_li_textbox">
                  <h3 class="slt_title"><strong>大数据科研支持</strong></h3>
                  <p class="slt_des">自定义内容及参数信息</p>
                  <div class="scr_tags">
                    <span class="scrt_tag"
                      ><span class="scrtt_content">多学科协同</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">数据共享</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row serb_cont">
          <div class="col-md-4 sc_left_con">
            <div class="sc_left">
              <div class="scl_imgbox">
                <!-- <img src="../assets/img/私有化@2x.png" alt="" /> -->
                <div
                  class="scl_imgbg scl_imgbg2"
                  :style="{
                    backgroundImage: `url(${require('../assets/img/公云版@2x.png')})`,
                  }"
                ></div>
              </div>
              <div class="scl_text">
                <p class="scl_title serb_cont2">平台-公云版</p>
                <p class="scl_des serb_cont2">适合个人健康管理使用</p>
              </div>
            </div>
          </div>
          <div class="col-md-8 sc_right">
            <div class="row scr_cont">
              <div class="col-md-4 scr_li">
                <div class="scr_li_textbox">
                  <h3 class="slt_title"><strong>快速筛查</strong></h3>
                  <p class="slt_des">对自我健康情况快速评估</p>
                  <div class="scr_tags">
                    <span class="scrt_tag"
                      ><span class="scrtt_content">公益性</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">经济性</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 scr_li">
                <div class="scr_li_textbox">
                  <h3 class="slt_title"><strong>数据安全</strong></h3>
                  <p class="slt_des">采用华为云、阿里云、腾讯云等平台服务</p>
                  <div class="scr_tags">
                    <span class="scrt_tag"
                      ><span class="scrtt_content">安全性</span></span
                    >
                    <span class="scrt_tag scrt_tag_ml"
                      ><span class="scrtt_content">隐秘性</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 scr_li">
                <div class="scr_li_textbox">
                  <h3 class="slt_title"><strong>多项基础服务</strong></h3>
                  <p class="slt_des">提供十几种健康管理功能服务</p>
                  <div class="scr_tags">
                    <span class="scrt_tag"
                      ><span class="scrtt_content">多样性</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 scr_li">
                <div class="scr_li_textbox">
                  <h3 class="slt_title"><strong>兼容性高</strong></h3>
                  <p class="slt_des">提供微信小程序、H5客户端服务</p>
                  <div class="scr_tags">
                    <span class="scrt_tag"
                      ><span class="scrtt_content">便捷性</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务特点 -->
    <div class="serviceCharacteristic">
      <div class="serviceCh_box container">
        <h1 class="pb_title">服务特点</h1>
        <div class="serceceCh_ul">
          <div class="serceceCh_li">
            <div class="sli_imgbox">
              <img src="../assets/img/服务人群@2x.png" alt="" />
            </div>
            <h3 class="sli_title">服务人群</h3>
            <div class="sli_line">
              <div class="sli_line_li"></div>
            </div>
            <div class="sli_desc">
              <p>
                基于医防融合慢性病管理服务体系的最基础理念，为卫生主管部门和健康管理者提供科学健康管理技术支持，提供客观、精准、连续的精准健康管理服务，实现预防和临床服务融入医务日常工作中。
              </p>
            </div>
          </div>
          <div class="serceceCh_li">
            <div class="sli_imgbox">
              <img src="../assets/img/多病种管理@2x.png" alt="" />
            </div>
            <h3 class="sli_title">多病种管理</h3>
            <div class="sli_line">
              <div class="sli_line_li"></div>
            </div>
            <div class="sli_desc">
              <p>
                以“三高共管、六病同防”医防融合慢性病管理服务体系为基础，实现多病种的研究、筛查、疾控、诊疗、急诊接入大数据平台内进行数据共享，形成一个“多入口、多出口、一中心”的集科研、应用于一体的数据共享生态。
              </p>
            </div>
          </div>
          <div class="serceceCh_li">
            <div class="sli_imgbox">
              <img src="../assets/img/人工智能@2x.png" alt="" />
            </div>
            <h3 class="sli_title">人工智能</h3>
            <div class="sli_line">
              <div class="sli_line_li"></div>
            </div>
            <div class="sli_desc">
              <p>
                采用2H2C模式，多维度的数据整合，建立数字疗法利用AI分析模型、远程监测手段等数字医疗技术为特定疾病患者提供专业、有效、个性化的疾病治疗干预以及患者管理。
              </p>
            </div>
          </div>
          <div class="serceceCh_li">
            <div class="sli_imgbox">
              <img src="../assets/img/个性化定制@2x.png" alt="" />
            </div>
            <h3 class="sli_title">个性化定制</h3>
            <div class="sli_line">
              <div class="sli_line_li"></div>
            </div>
            <div class="sli_desc">
              <p>
                专业的医疗行业背景开发团队，可以快速响应客户需求，在保障质量前提下交付，售后服务短时间及时响应，达到客户满意效果。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 大数据管理系统 -->
    <div id="bigData" v-if="0" class="container-fuild">
      <div class="row bigData-container">
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn">
          <img class="img-responsive" src="@/assets/img/img1.png" alt="大数据管理系统" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6">
          <h2 class="bigData-title">
            大数据管理系统
            <small>/ Big Data Management System</small>
          </h2>
          <p>
            当今最领先的响应式自助建站平台。无论您是普通互联网用户，还是专业网站制作人员，都能使用起飞页设计出最具专业水准的网站。想创建一个简单的单页式站点，还是一个专业的公司网站，亦或是一个别具一格的博客？起飞页可以满足您的所有需求。
          </p>
          <p>
            我们的流线式网页布局设计方案和可视化图文内容编辑模式让网站制作和维护成为一件轻松惬意的事。无论您是普通互联网用户，还是专业网站制作人员。
          </p>
          <h2 class="bigData-device">PC/PAD/Phone &nbsp; 全设备支持</h2>
          <a href="#" class="btn btn-lg btn-block btn-info">联系我们</a>
        </div>
      </div>
    </div>
    <!-- 您身边的IT专家 -->
    <div id="contactUs" v-if="0" class="container-fuild text-center">
      <div class="container contactUs-container wow slideInUp">
        <h1>您身边的IT专家</h1>
        <h3>7x24小时提供出色的IT服务</h3>
        <button
          class="btn btn-default btn-sm"
          onmouseleave="this.style.borderColor='#ffffff'; this.style.backgroundColor='#ffffff'; this.style.color='#3f3f3f';"
          onmouseenter="this.style.backgroundColor='transparent'; this.style.borderColor='#ffffff'; this.style.color='#ffffff';"
        >
          联系我们
        </button>
        <div class="contactUs-contactWay">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <!-- 客户评价 -->
    <div id="customer" v-if="0" class="container-fuild">
      <div class="container customer-container">
        <p class="customer-title text-center">客户评价</p>
        <div class="swiper-container customer-swiper hidden-xs">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide customer-block"
              v-for="(item, index) in customerList"
              :key="index"
            >
              <div class="customer-logo">
                <img class="center-block" :src="item.logo" alt="logo" />
              </div>
              <div class="customer-yh">
                <img src="@/assets/img/yinhao.png" alt="引号" />
              </div>
              <div class="customer-content1">
                <small>{{ item.content }}</small>
              </div>
              <div class="customer-content2">{{ item.title }}</div>
            </div>
          </div>
          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div class="row visible-xs customer-block">
          <div class="col-xs-12" v-for="(item, index) in customerList" :key="index">
            <div class="customer-logo">
              <img class="center-block" :src="item.logo" alt="logo" />
            </div>
            <div class="customer-yh">
              <img src="@/assets/img/yinhao.png" alt="引号" />
            </div>
            <div class="customer-content1">
              <small>{{ item.content }}</small>
            </div>
            <div class="customer-content2">
              <small>{{ item.title }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 为什么选择我们 -->
    <div id="whyChooseUs" v-if="0" class="conatiner-fuild">
      <div class="container">
        <div class="whyChooseUs-title text-center">
          <p>为什么选择我们的服务</p>
          <p>THE REASON TO CHOOSING US</p>
        </div>
        <div class="row">
          <div
            class="col-xs-12 col-sm-6 col-md-3 server-wrapper"
            v-for="(item, index) in serverList"
            :key="index"
          >
            <div
              class="server-block wow slideInUp"
              onmouseenter="this.style.color='#28f';this.style.borderColor='#28f'"
              onmouseleave="this.style.color='#666';this.style.borderColor='#ccc'"
            >
              <img class="center-block" :src="item.logo" alt="logo" />
              <p class="text-center">{{ item.title }}</p>
              <div
                class="text-center"
                v-html="item.content"
                onmouseenter="this.style.color='#28f'"
                onmouseleave="this.style.color='#ccc'"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VideoDialog
      :visible.sync="dialogVisible"
      :video-src="videoSrc"
      @update:visible="dialogVisible = $event"
    >
      <!-- @close-dialog="closeModal" -->
    </VideoDialog>
    <!-- <FooterVue></FooterVue> -->
  </div>
</template>
<script>
import Swiper from "swiper";
import { WOW } from "wowjs";
import $ from "jquery";
import VideoDialog from "@/components/VideoDialog.vue";

export default {
  name: "HomePage",
  data() {
    return {
      swiperList: [
        {
          img: require("@/assets/img/banner1.png"),
          path: "",
          title: "您身边的IT专家1",
          content: "宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介",
        },
        {
          img: require("@/assets/img/banner2.jpg"),
          path: "",
          title: "您身边的IT专家2",
          content: "宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介",
        },
        {
          img: require("@/assets/img/banner1.png"),
          path: "",
          title: "您身边的IT专家3",
          content: "宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介",
        },
        {
          img: require("@/assets/img/banner2.jpg"),
          path: "",
          title: "您身边的IT专家4",
          content: "宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介宣传简介",
        },
      ],
      customerList: [
        {
          logo: require("@/assets/img/logo_hp.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_kk.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_toyota.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_kk.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_hp.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_toyota.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_kk.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_hp.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_toyota.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_hp.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_kk.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
        {
          logo: require("@/assets/img/logo_hp.png"),
          title:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
          content:
            "您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。您可以双击这里或者点击编辑按钮来修改内容。您还可以添加图标，按钮，图片等常用元素。",
        },
      ],
      serverList: [
        {
          logo: require("@/assets/img/tel.png"),
          title: "核心优势1",
          content: "<p>由专业客服提供人工服务</p>负责疑难问题和故障受理",
        },
        {
          logo: require("@/assets/img/computer.png"),
          title: "核心优势2",
          content: "<p>利用远程视频工具，提供协助</p>帮助客户进行调试、解决故障",
        },
        {
          logo: require("@/assets/img/qq.png"),
          title: "核心优势3",
          content: "<p>利用企业QQ提供在线解答</p>帮助企业快速准确解决问题和故障",
        },
        {
          logo: require("@/assets/img/skill.png"),
          title: "核心优势4",
          content: "<p>由技术支持工程师，负责问题解答</p>需求受理及故障受理",
        },
      ],
      productSchemeList: [
        {
          logo: require("@/assets/img/血压@2x.png"),
          title: "血压",
          productScheme: "远程全血压管理分析系统",
          btnflag: true,

          content:
            "血压指血液在血管内流动时作用于单位面积血管壁的侧压力，为推动血液在人体血管中流动的动力，是人的基本生命体征之一。检查所测量的一般是体循环的动脉血压，包括收缩压与舒张压。测量血压为临床体格检查的一个重要项目，血压正常代表人体心脏和血管都处于正常运转状态。该指标的异常可由多种生理性或病理性因素导致。",
        },
        {
          logo: require("@/assets/img/血糖@2x.png"),
          title: "血糖",
          productScheme: "远程全血糖管理分析系统",
          btnflag: true,

          content:
            "体内各组织细胞活动所需的能量大部分来自葡萄糖，所以血糖必须保持一定的水平才能维持体内各器官和组织的需要。血糖过高可导致机体脱水及高渗状态、电解质紊乱及酸中毒、胰岛功能衰竭、引起消瘦、乏力、抵抗力降低、各种血管、神经慢性并发症。",
        },
        {
          logo: require("@/assets/img/血脂 @2x.png"),
          title: "血脂",
          productScheme: "暂无",
          btnflag: false,

          content:
            "人体的血液犹如汽车的汽油，血浆中胆固醇或甘油三酯含量高，就相当于汽油中的杂质多或油路不通畅，那么高血脂及其并发症的发生就不可避免。大量研究资料表明，高血脂症是脑卒中、冠心病、心肌梗死、猝死的危险因素。此外，高血脂症也是促进高血压、糖耐量异常、糖尿病的一个重要危险因素。",
        },
        {
          logo: require("@/assets/img/血氧@2x.png"),
          title: "血氧",
          productScheme: "暂无",
          btnflag: false,

          content:
            "血氧饱和度(Blood oxygen saturation) 是血液中氧气与血红蛋白 (Hb) 结合的程度，是单位血红蛋白含氧百分位数，分为动脉血氧饱和度、静脉血氧饱和度和经皮血氧饱和度，可作为判断机体是否缺氧的一个指标，但相较于氧分压，精准度有所不足。",
        },
        {
          logo: require("@/assets/img/睡眠呼吸@2x.png"),
          title: "睡眠呼吸",
          productScheme: "远程睡眠呼吸监测系统",
          content:
            "基于《成人阻塞性睡眠呼吸暂停高危人群筛查与管理专家共识》研发出睡眠监测全流程管理：家庭睡眠呼吸暂停监测筛查、多导睡眠监测和家用呼吸机监测，提高OSA高风险人群开展筛查及早期干预效果。",
        },
        {
          logo: require("@/assets/img/心电图@2x.png"),
          title: "心电",
          productScheme: "远程心电数据管理系统",
          btnflag: true,

          content:
            "心电图是针对受检者心脏电活动进行的检查，根据不同的情况，可以选择常规心电图、超声心动检查、24小时心电检测等项目。通过该项检查，可以了解受检者是否存在心肌缺血、心脏扩大、心律失常、心肌梗死等疾病，同时可以作为心脏病患者的监测指标。对于心脏病、肺心病等人群具有重要且不可忽略的意义。",
        },
        {
          logo: require("@/assets/img/心理压力@2x.png"),
          title: "心理压力",
          productScheme: "心率变异性分析检测",
          btnflag: true,

          content:
            "精神压力分析仪通过分析交感与副交感神经调控心脏的相互作用，自动测量自助神经系统的活性，反映其交感和副交感神经的平衡能力，并依据心率变异性的相关因素，评估和分析受检者的身体疲劳度和精神压力状况",
        },
        {
          logo: require("@/assets/img/人体成分@2x.png"),
          title: "体脂",
          productScheme: "暂无",
          btnflag: false,

          content:
            "体脂率的意思就是人的脂肪所占总体重的比例。反映的是人体脂肪含量的多少，可以用来衡量肥胖程度。但是体脂率不是固定的值，不同年龄、不同性别的人，体脂率都会不同，也会随着人的饮食、运动等情况而改变。",
        },
      ],
      videoSrc: require("@/assets/video/高血压管理中心.mp4"),
      // videoSrc:
      // "https://www.iflyhealth.com/upload/20221205/5b438604b852c6165e639b687d763371.mp4",
      dialogVisible: false,
    };
  },
  mounted() {
    /* banner-swiper */
    new Swiper(".banner-swiper", {
      loop: true, // 循环模式选项
      effect: "fade",
      //自动播放
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // 延迟加载
      lazy: {
        loadPrevNext: true,
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
    });
    /* customer-swiper */
    new Swiper(".customer-swiper", {
      loop: true, // 循环模式选项
      slidesPerView: 3,
      //自动播放
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
    });
    /* wowjs动画 */
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },
  methods: {
    pb_content_li_mouseenter(a, b) {
      console.log("$event", a);
      console.log("$b", b);
      console.log("$a", $(a.target));
      var domsarr = $(".pb_content_li").get();
      console.log("domsarr", domsarr);
      domsarr.forEach((element) => {
        element.classList.remove("hovered");
      });
      $(a.target).addClass("hovered");
    },
    pb_content_ul_mouseleaver() {
      var domsarr = $(".pb_content_li").get();
      console.log("domsarr", domsarr);
      domsarr.forEach((element) => {
        element.classList.remove("hovered");
      });
    },
    showDialog() {
      this.dialogVisible = true;
    },
    closeModal() {
      this.dialogVisible = false;
      /*  this.$nextTick(() => {
        const dialogWrapperEl = document.querySelector(".dialog-wrapper");
        dialogWrapperEl.parentNode.removeChild(dialogWrapperEl);

        const dialogEl = document.querySelector(".fullscreen-dialog");
        dialogEl.parentNode.removeChild(dialogEl);
      }); */
    },
  },
  components: { VideoDialog },
};
</script>
<style scoped lang="scss">
/* 整体盒子 */
#HomePage {
  width: 100%;
  .z_mobile {
    position: relative;
    padding: 4.5vw 10vw;
    box-sizing: border-box;
    min-height: 35vw;
    z-index: 1;
    .lazy {
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      background-position: bottom center;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      background-attachment: fixed;
      background-image: url(../assets/img/观看视频bg.png);
      background-attachment: inherit;
    }
    .scl_text {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      width: 100%;
      padding: 0 10% 0 15%;
      width: 61.95vw;
      .scl_title {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        font-size: 3.12vw;
        &.serb_cont2 {
          color: #111111;
        }
      }
      .scl_des {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        font-size: 1.12vw;
        @media screen and (min-width: 576px) {
          display: -webkit-box !important;
        }
        &.serb_cont2 {
          color: #111111;
        }
      }
      .btn {
        // font-size: 1.24vw;
        font-size: 16px;
        padding: 8px 15px;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 38px;
        // height: 28px;
        text-align: center;
        // line-height: 100%;
        line-height: 1;
        margin-top: 20px;
        @media screen and (max-width: 980px) {
          margin-top: 8px;
          padding: 5px 12px;
          font-size: 12px;
        }
      }
    }
  }
  .productScheme {
    .product_box {
      .pb_title {
        // font-size: 50px;
        font-size: 2.2vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #111111;
        margin: 45px 0 35px 0;
        @media screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 24px;
        }
      }
      .pb_content_ul {
        width: 100%;
        // padding: 0px 15px;
        margin: 0 auto;
        display: flex;
        align-content: space-around;
        flex-flow: row nowrap;
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
        }
        .pb_content_li {
          // transition: all 2s;
          margin: 0 15px 0px 0px;
          @media screen and (max-width: 768px) {
            &:last-child {
              margin-bottom: 0px;
            }
            margin-bottom: 100px;
          }
          width: 140px;
          min-height: 386px;
          position: relative;
          /* overflow: hidden; */
          cursor: pointer;
          //  flex: 0 1 30%;
          cursor: pointer;
          overflow: hidden;
          @media screen and (max-width: 768px) {
            flex-basis: 100%;
          }
          // &:hover {
          &.hovered {
            width: 362px;
            flex-shrink: 0;
            overflow: visible;

            .pc_li_c1 {
              // display: none;
              // width: 0;
              opacity: 0;
              // transition: all 2s;
            }
            .pc_li_c2 {
              display: block;
              // width: 362px;
              padding: 27px 39px 43px;
              opacity: 1;
              // transition: all 2s;
            }
          }
          .pc_li_c1 {
            display: flex;
            opacity: 1;
            flex-flow: column;
            justify-content: space-between;
            background-color: #f8fbfe;
            // background-color: #ee380f;
            // width: 140px;
            width: 100%;
            height: 100%;
            // overflow: hidden;
            border-radius: 6px;
            // transition: all 2s;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            .plc1_title {
              text-align: center;
              font-size: 20px;
              padding-top: 20px;

              @media screen and (max-width: 768px) {
                font-size: 50px;
              }
            }
            .plc1_img {
              img {
                width: 100%;
                max-width: 349px;
                margin: 0 auto;
              }
            }
          }
          .pc_li_c2 {
            // display: none;
            opacity: 0;
            // width: 362px;
            // width: 0px;
            width: 100%;
            // padding: 27px 39px 43px;
            padding: 0px;
            height: 100%;
            background: #4ba9ff;
            box-shadow: 0px 13px 43px 0px rgba(57, 140, 225, 0.26);
            border-radius: 6px;
            color: #fff;
            position: absolute;
            top: 0px;
            left: 0px;
            // transition: all 2s;

            .plc2_title {
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: bold;
            }
            .plc2_line {
              width: 41px;
              height: 4px;
              margin: 14px 0 30px;
              background: #ffffff;
            }
            .plc2_desc {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              text-overflow: ellipsis;
              text-overflow: ellipsis;
              overflow: hidden;
              /* white-space: nowrap; */
              -webkit-line-clamp: 7;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
            .plc2_btn {
              // margin-top: 40px;
              margin-top: 10%;
              .plc2_button {
                width: 160px;
                height: 46px;
                background: #2d6da8;
                opacity: 0.7;
                border-radius: 23px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                position: relative;
                text-align: center;
                font-weight: 400;
                color: #fefefe;
                left: 50%;
                transform: translateX(-50%);
                line-height: 46px;
              }
            }
            .plc2_img {
              position: absolute;
              top: calc(100% + 10px);
              width: 100px;
              left: 50%;
              transform: translateX(-50%);
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .serviceSector {
    &.serviceSector_con {
      background-color: #f8fbfe;
    }
    .service_box {
      .pb_title {
        // font-size: 50px;
        font-size: 2.2vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #111111;
        margin: 45px 0 35px 0;
        @media screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 24px;
        }
      }
      .serb_cont {
        // height: 10.875vw;
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 8px;
        .sc_left_con {
          // height: 100%;
          @media screen and (max-width: 970px) {
            flex-basis: 100%;
            min-height: 10.48vh;
          }
          @media (min-width: 970px) {
            width: 33.33333333%;
          }
          .sc_left {
            /*  background: url("../assets/img/私有化@2x.png") 0 0 no-repeat;
        background: linear-gradient(269deg, #a2d9ff, #2eb3ff);
        box-shadow: 0px 3px 14px 2px rgba(57, 140, 225, 0.5); */
            width: 100%;
            height: 100%;
            position: relative;
            .scl_imgbox {
              width: 100%;
              // height: 10.875vw;
              height: 100%;
              // border-radius: 20px;
              // overflow: hidden;
              .scl_imgbg {
                position: relative;
                background-image: url("../assets/img/私有化@2x.png");
                // width: 100%;
                height: 100%;
                transition: ease 1s;
                background-position: right center;
                background-position: center;
                background-position: center right;
                background-repeat: no-repeat;
                background-size: contain;
                background-size: auto 100%;
                &.scl_imgbg2::after {
                  box-shadow: none;
                  background: linear-gradient(
                    269deg,
                    rgb(172, 203, 238, 0.2) 10%,
                    rgb(231, 240, 253)
                  );
                }
                &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(
                    272deg,
                    rgb(163, 217, 255, 0.2) 10%,
                    rgb(46 179 255)
                  );
                  box-shadow: 0px 3px 14px 2px rgba(57, 140, 225, 0.5);
                }
                //  background-size: cover;
              }
            }
            .scl_text {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              z-index: 1;
              width: 100%;
              padding: 0 10% 0 15%;
              .scl_title {
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                &.serb_cont2 {
                  color: #111111;
                }
              }
              .scl_des {
                font-size: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                &.serb_cont2 {
                  color: #111111;
                }
              }
            }
          }
        }

        .sc_right {
          height: 100%;
          display: flex;
          flex: 1;
          .scr_cont {
            height: 100%;
            padding: 10px 0;
            display: flex;
            flex: 1;
            flex-flow: row wrap;
            justify-content: space-between;
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
            .scr_li {
              position: relative;
              height: 100%;
              padding: 10px 10px;
              @media screen and (max-width: 970px) {
                text-align: center;
                width: 100%;
                // width: 33.333333%;
              }
              @media (min-width: 992px) {
                width: 33.33333333%;
              }
              .scr_li_textbox {
                // position: relative;
                // left: 0;
                // top: 50%;
                // transform: translateY(-50%);
                // z-index: 1;
                width: 100%;
                position: relative;
                left: 0;
                top: 0;
                bottom: 0;
                /* transform: translateY(-50%); */
                z-index: 1;
                width: 100%;
                margin: auto;
                // padding: 0 10% 0 15%;
                .slt_title {
                  margin-top: 0px;
                  font-size: 15px;
                }
                .slt_des {
                  font-size: 12px;
                }
                .scr_tags {
                  .scrt_tag {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    height: 24px;
                    padding: 0 9px;
                    font-size: var(--el-tag-font-size);
                    border: 1px solid;
                    line-height: 1;
                    margin-right: 3px;
                    margin-bottom: 5px;
                    .scrtt_content {
                      font-size: 12px;
                    }
                    &.scrt_tag_ml {
                      margin-left: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .serviceCharacteristic {
    .serviceCh_box {
      .pb_title {
        // font-size: 50px;
        font-size: 2.2vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #111111;
        margin: 45px 0 35px 0;
        @media screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 24px;
        }
      }
      .serceceCh_ul {
        margin-left: -3%;
        display: flex;
        /* width: 100%; */
        /* padding: 0px 15px; */
        margin: 0 auto;
        display: flex;
        align-content: space-around;
        flex-flow: row wrap;
        margin-left: -3%;
        margin-bottom: 50px;
        @media screen and (max-width: 970px) {
          margin-left: 0;
        }

        .serceceCh_li {
          margin-left: 3%;
          padding: 35px;
          // background: #97c0e9;
          background: #f8fbfe;
          // min-height: 29.58vh;
          min-height: 13.58vw;
          // height: 38vh;
          width: 22%;

          @media screen and (max-width: 970px) {
            border-radius: 15px;
            width: 100%;
            margin-left: 0;
            margin-bottom: 12px;
            padding: 20px 12px !important;
            height: auto;
          }
          .sli_imgbox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            img {
              width: 30%;
              height: auto;
              @media screen and (max-width: 970px) {
                width: 100px;
              }
            }
          }
          .sli_title {
            margin-top: 15px;
            text-align: center;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #111111;
          }
          .sli_line {
            /* width: 44px; */
            /* height: 4px; */
            /* background: #1890FF; */
            margin: 12px 0px;
            /* text-align: center; */
            display: flex;
            justify-content: center;
            .sli_line_li {
              width: 44px;
              height: 4px;
              background: #1890ff;
              text-align: center;
            }
          }
          .sli_desc {
            -webkit-line-clamp: 7;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            max-height: 170px;
            font-size: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 8;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: 970px) {
              max-height: none;
              -webkit-line-clamp: 999;
            }
            p {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@keyframes pc_li_c1_hide {
  from {
  }
  to {
  }
}
/* 轮播图 */
#swiper {
  height: 600px;
}
#swiper .banner-swiper {
  width: 100%;
  height: 100%;
}
#swiper .banner-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
}
#swiper .banner-swiper .swiper-slide {
  position: relative;
}
#swiper .banner-swiper .swiper-slide-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(51, 51, 51, 0.534);
  text-align: center;
  line-height: 80px;
}
#swiper .banner-swiper .swiper-slide-title > h1 {
  font-size: 50px;
  margin-top: 12%;
}
#swiper .banner-swiper .swiper-slide-title > p {
  font-size: 20px;
  margin-top: 1%;
  font-weight: 700;
}
/* 大数据管理系统 */
#bigData {
  padding: 100px;
  transition: all ease 0.6s;
  box-sizing: border-box;
}
#bigData .bigData-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
#bigData p {
  font-size: 14px;
  color: #333;
  line-height: 2rem;
}
#bigData .bigData-device {
  margin: 50px 0 20px;
}

/* 您身边的IT专家 */
#contactUs {
  color: #fff;
  height: 400px;
  background: url("../assets/img/contact_us_bg.jpg") 0 0 no-repeat;
  background-size: 100% 100%;
  transition: all ease 0.6s;
}
#contactUs .contactUs-container {
  padding-top: 50px;
}
#contactUs .contactUs-container button {
  width: 300px;
  height: 50px;
  margin-top: 40px;
}
#contactUs .contactUs-container .contactUs-contactWay span {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 30px;
}
#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(1) {
  background: url("../assets/img/weixin.png") 0 0 no-repeat;
  background-size: 100% 100%;
}
#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(2) {
  background: url("../assets/img/weibo.png") 0 0 no-repeat;
  background-size: 100% 100%;
}
#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(3) {
  background: url("../assets/img/twitter.png") 0 0 no-repeat;
  background-size: 100% 100%;
}

/* 客户评价 */
#customer {
  padding: 50px 0;
  box-sizing: border-box;
  background: #efefef;
  transition: all ease 0.6s;
}
#customer .customer-title {
  font-size: 30px;
  color: rgb(102, 102, 102);
  margin: 0 0 30px;
}
#customer .customer-block {
  background: #fff;
  padding: 30px;
}
#customer .customer-logo img {
  width: 94px;
  height: 94px;
  border: 1px solid #ccc;
}
#customer .customer-yh img {
  width: 34px;
  height: 34px;
}
#customer .customer-content1 {
  padding-bottom: 20px;
  border-bottom: 1px solid #0ce9f1;
}
#customer .customer-content2 {
  padding-top: 20px;
}
/* 为什么选择我们 */
#whyChooseUs {
  padding: 100px;
}
#whyChooseUs .whyChooseUs-title {
  margin-bottom: 50px;
}
#whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
  font-size: 25px;
  font-weight: 500;
}
#whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
  font-size: 14px;
}
#whyChooseUs .server-block {
  padding: 50px 20px;
  border: 1px solid #ccc;
  border-bottom: 5px solid #ccc;
}
#whyChooseUs .server-block img {
  width: 48px;
  height: 48px;
}
#whyChooseUs .server-block > p {
  font-size: 20px;
  margin: 30px 0;
}
#whyChooseUs .server-block > div {
  color: #ccc;
}
/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  #swiper {
    height: 200px;
  }
  #bigData {
    padding: 30px;
  }
  #bigData .bigData-title {
    font-size: 20px;
  }
  #bigData .bigData-device {
    font-size: 20px;
    margin: 10px 0 10px;
  }
  #contactUs {
    height: 200px;
    transition: all ease 0.6s;
  }
  #contactUs .contactUs-container {
    padding-top: 0;
  }
  #contactUs .contactUs-container h1 {
    font-size: 25px;
  }
  #contactUs .contactUs-container h3 {
    font-size: 18px;
  }
  #contactUs .contactUs-container button {
    width: 200px;
    height: 30px;
    margin-top: 20px;
  }
  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 10px;
  }
  #customer {
    padding: 30px 0;
    box-sizing: border-box;
    background: #fff;
  }
  #customer .customer-title {
    font-size: 16px;
    font-weight: bold;
  }
  #customer .customer-logo img {
    width: 48px;
    height: 48px;
  }
  #customer .customer-block {
    padding: 30px;
  }
  #customer .customer-block > div {
    padding: 30px 0;
  }
  #whyChooseUs {
    padding: 20px 0;
    transition: all ease 0.6s;
  }
  #whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
    font-size: 20px;
    font-weight: 700;
  }
  #whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
    font-size: 12px;
  }
  #whyChooseUs .server-block {
    padding: 50px 0;
    border: 1px solid #ccc;
    border-bottom: 5px solid #ccc;
  }
  #whyChooseUs .server-block img {
    width: 48px;
    height: 48px;
  }
  #whyChooseUs .server-block > p {
    font-size: 20px;
    margin: 30px 0;
  }
  #whyChooseUs .server-block > div {
    color: #ccc;
  }
}

/* 媒体查询（平板） */
@media screen and (min-width: 768px) and (max-width: 996px) {
  #swiper {
    height: 400px;
  }
  #bigData {
    padding: 60px;
  }
  #bigData .bigData-title {
    font-size: 30px;
  }
  #bigData .bigData-device {
    font-size: 30px;
    margin: 30px 0 15px;
  }
  #contactUs {
    height: 300px;
  }
  #contactUs .contactUs-container {
    padding-top: 50px;
  }
  #contactUs .contactUs-container h1 {
    font-size: 30px;
  }
  #contactUs .contactUs-container h3 {
    font-size: 20px;
  }
  #contactUs .contactUs-container button {
    width: 300px;
    height: 50px;
    margin-top: 30px;
  }
  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 15px;
  }
  #customer .customer-title {
    font-size: 24px;
  }
  #whyChooseUs {
    padding: 20px 0;
  }
}
</style>
