<template>
  <div id="app">
    <HeaderVue></HeaderVue>
    <router-view class="view" />
    <FooterVue></FooterVue>
    <GoTop></GoTop>
  </div>
</template>
<script>
// import HeaderVue from "./components/HeaderVue.vue";

// @ is an alias to /src

export default {
  name: "ProductCenter",
  // components: { HeaderVue },
};
</script>

<style lang="scss">
#app {
  position: relative;
}
.view {
  width: 100%;
  overflow: hidden;
}
</style>
