import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomePage.vue'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '*',
  //   redirect: '/'
  // }, 
  {
    path: '/',
    name: '',
    redirect: '/home'
  },
  // {
  //   path: '/',
  //   component: resolve => require(['@/views/PageView'], resolve),
  //   children: [
  //     {
  //       path: '/',
  //       redirect: '/home'
  //     },
  //     {
  //       path: '/home',
  //       name: 'home',
  //       component: resolve => require(['@/views/HomePage'], resolve),
  //       meta: {
  //         title: '医云康泰-首页',
  //         headerfixd: true
  //       }
  //     },
  //     {
  //       path: '/solution',
  //       name: 'solution',
  //       component: resolve => require(['@/views/SolutionPage'], resolve),
  //       meta: {
  //         title: '解决方案',
  //         headerfixd: true
  //       }
  //     },
  //     {
  //       path: '/software',
  //       component: resolve => require(['@/views/SoftwareVue'], resolve),
  //       meta: {
  //         title: '软件产品'
  //       },
  //       children: [
  //         {
  //           path: '',
  //           redirect: '/software/smartTown'
  //         },
  //         {
  //           path: '/software/smartTown',
  //           name: 'software',
  //           component: resolve => require(['@/views/Software_smartTown'], resolve),
  //           meta: {
  //             title: '软件产品丨智能小镇管理系统'
  //           }
  //         },
  //         {
  //           path: '/software/bigData',
  //           name: 'software2',
  //           component: resolve => require(['@/views/Software_bigData'], resolve),
  //           meta: {
  //             title: '软件产品丨大数据管理系统'
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/ServiceVue',
  //       name: 'ServiceVue',
  //       component: resolve => require(['@/views/ServiceVue'], resolve),
  //       meta: {
  //         title: '相关服务'
  //       }
  //     },
  //     {
  //       path: '/newsinformation',
  //       name: 'newsinformation',
  //       component: resolve => require(['@/views/NewsInformation'], resolve),
  //       meta: {
  //         title: '新闻动态'
  //       }
  //     },
  //     {
  //       path: '/companyintroduction',
  //       name: 'companyintroduction',
  //       component: resolve => require(['@/views/CompanyIntroduction'], resolve),
  //       meta: {
  //         title: '公司介绍'
  //       }
  //     },
  //     {
  //       path: '/jobchance',
  //       name: 'jobchance',
  //       component: resolve => require(['@/views/JobChance'], resolve),
  //       meta: {
  //         title: '工作机会'
  //       }
  //     },
  //     {
  //       path: '/contactus',
  //       name: 'contactus',
  //       component: resolve => require(['@/views/ContactUs'], resolve),
  //       meta: {
  //         title: '联系我们'
  //       }
  //     },
  //     {
  //       path: '/servicedetail',
  //       name: 'servicedetail',
  //       component: resolve => require(['@/views/ServiceDetail'], resolve),
  //       meta: {
  //         title: '相关服务'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      title: '医云康泰-首页',
      headerfixd: true
    }
  },
  {
    path: '/productjc',
    name: 'productjc',
    component: resolve => require(['@/views/productIntroduce/productJc'], resolve),
    meta: {
      title: '产品介绍丨智慧运营决策管理系统'
    }
  },
  {
    path: '/productbp',
    name: 'productbp',
    component: resolve => require(['@/views/productIntroduce/productBp'], resolve),
    meta: {
      title: '产品介绍丨智慧全血压管理系统'
    }
  },
  {
    path: '*',
    // redirect: '/',
    redirect: '/home'
    // component: HomeView
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  /*  scrollBehavior(to, from, savedPosition) {
     // return 期望滚动到哪个的位置
     to, from,savedPosition;
     return { x: 0, y: 0 }
   } */
})
router.beforeEach((to, form, next) => {
  window.scroll(0, 0);
  next()
})

export default router
